import React, { useRef, useState, useEffect } from 'react';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Configure,
  Pagination,
  RefinementList,
  ClearRefinements,
  Stats,
} from 'react-instantsearch-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { database_sources, TYPESENSE_COLLECTION_NAME } from './constants';
import qs from 'qs';
import ReactModal from 'react-modal';

!(process.env.NODE_ENV === 'test') && ReactModal.setAppElement('#root');


const { WORDPRESS, ATOM, SOUTRON } = database_sources;

/**
 * The createURL method is based on the qs module.
 * The method turns the search state into a string, which the searchStateToUrl method can then convert into a URL.
 * The urlToSearchState does the opposite: it creates a search state from a URL.
 *
 * https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/
 */

const createURL = (state) => `?${qs.stringify(state)}`;

const searchStateToUrl = (searchState) =>
  searchState ? createURL(searchState) : '';

const urlToSearchState = ({ search }) => qs.parse(search.slice(1));

const DEBOUNCE_TIME = 400;

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: process.env.REACT_APP_TYPESENSE_SEARCH_ONLY_API_KEY,
    nodes: [
      {
        host: process.env.REACT_APP_TYPESENSE_HOST,
        port: process.env.REACT_APP_TYPESENSE_PORT,
        protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL,
      },
    ],
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    query_by:
    // todo use schema for this
      'title,creator,identifier,database_source,dateCreated,location,publisher,collection,rights,language,subject,format,description_one,description_two,contributor,source',
    facet_by: 'dateCreated,location,format,database_source',
    exhaustive_search: true,
    prefix: false,
    num_typos: 0,
  },
});

const searchClient = typesenseInstantsearchAdapter.searchClient;

function App() {
  let location = useLocation();
  let navigate = useNavigate();

  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const debouncedSetStateRef = useRef(null);

  function onSearchStateChange(updatedSearchState) {
    clearTimeout(debouncedSetStateRef.current);

    debouncedSetStateRef.current = setTimeout(() => {
      navigate(searchStateToUrl(updatedSearchState));
    }, DEBOUNCE_TIME);

    setSearchState(updatedSearchState);
  }

  useEffect(() => {
    setSearchState(urlToSearchState(location));
  }, [location]);

  return (
    <div>
      <header className="header shadow-lg">
        <div className="container">
          <div className="header-logo">
          <a href="https://www.itma.ie/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="110.19"
              height="40"
              viewBox="0 0 177.631 64.48"
            >
              <path
                id="itma-logo"
                d="M43.773,55.48c-7.195,0-10.868-4.805-10.868-12.217,0-6.6,2.963-11.576,9.212-13.91,3.794-1.417,7.35-1.766,12.462-1.766H56.7V18.82H17.9v8.665H32.543C25.7,30.836,22.37,37.741,22.37,44.956,22.37,57.834,30.1,64.8,43.346,64.8A35.969,35.969,0,0,0,57,61.892l-2.809-8.65a23.924,23.924,0,0,1-10.417,2.239Zm69.143-37.43c-6.4,0-10.494,1.822-13.845,6.7-1.9-4.614-5.5-6.706-11.855-6.706A16.062,16.062,0,0,0,74.6,23.5a47.047,47.047,0,0,0-1.105-5.21l-9.566,1.26a83.366,83.366,0,0,1,1.506,15.856l.1,28.624h9.638V40.388c0-8.863,3.162-13.223,9.035-13.223,6.525,0,8.232,4.709,8.232,13.223V64.031h9.638V40.388c0-9.062,3.363-13.223,9.135-13.223,6.325,0,7.63,4.207,7.63,13.223V64.031h9.637V37.183c0-12.21-4.457-19.134-15.56-19.134ZM1.382,64.031h10.04V18.25l-10.04,1.3ZM6.4.317A6.31,6.31,0,0,0,.077,6.728,6.23,6.23,0,0,0,6.4,13.04a6.17,6.17,0,0,0,6.325-6.312A6.253,6.253,0,0,0,6.4.317ZM160.64,18.254,150.25,19.6,133.083,64.031h10.239L146.28,56c6.02,5.976,12.173,8.247,22.927,8.247a41.338,41.338,0,0,0,8.5-1.1l-17.069-44.9ZM149.326,47.763l6.244-16.955L164.9,55.546A21.725,21.725,0,0,1,149.326,47.763Z"
                transform="translate(-0.077 -0.317)"
                fill="#fff"
              />
            </svg>
            </a>
          </div>
        </div>
      </header>

      <div className="container container-padding border-radius-30 margin-30 shadow-lg">
        <InstantSearch
          searchClient={searchClient}
          indexName={TYPESENSE_COLLECTION_NAME}
          searchState={searchState}
          onSearchStateChange={onSearchStateChange}
          createURL={createURL}
        >
          <Configure hitsPerPage={21} />
          <div className="search-panel">
            <div className="filter-show">Show Filters</div>
            <input
              type="checkbox"
              name="one"
              id="item-3"
              className="hide-input"
            />

            <div className="search-panel__filters">
              <p className="green-text remove-margin">Source</p>
              <RefinementList attribute="database_source" />
              <ClearRefinements />
              <p className="green-text remove-margin">Material Type</p>
              <RefinementList attribute="format" showMore />
              <p className="green-text remove-margin">Temporal</p>
              <RefinementList attribute="dateCreated" showMore />
              <p className="green-text remove-margin">People</p>
              <RefinementList attribute="creator" showMore />
              <p className="green-text remove-margin">Geographical Location</p>
              <RefinementList attribute="location" showMore />

              <p className="green-text remove-margin"></p>
              <ul className="source-list">
              <li>
                <p className="marginTop0 green-text">
                  These colours indicate where each search result item is being sourced
                </p>
              </li>
                <li className="redList">{WORDPRESS}</li>
                <li className="blueList">{ATOM}</li>
                <li className="purpleList">{SOUTRON}</li>


              </ul>
            </div>

            <div className="search-panel__results">
            <strong>Beta search functionality</strong><br />
            For a complete search please visit the <a href="https://itmacatalogues.ie/Portal/Default/en-GB/Search/SimpleSearch" target="_blank">Library</a> and <a href="https://itma.accesstomemory.org/" target="_blank">Archive catalogues</a><br />
            <br />

              <div id="search" className="modalDialog">
                <div className="bg-red border-radius-30">
                  <div className="innerpadding-large">
                    <h2 className="remove-margin black-text">Search</h2>
                    <p className="remove-margin black-text">
                      Explore our entire ITMA collection via our federated
                      search
                    </p>
                    <SearchBox
                      data-testid="search"
                      className="searchbox"
                      searchAsYouType={false}
                      translations={{
                        placeholder: 'what do you want to find?',
                      }}
                    />
                    <a href="#close" title="Close" class="close">
                      X
                    </a>
                  </div>
                </div>
              </div>

                <SearchBox
                  data-testid="search"
                  className="searchbox"
                  searchAsYouType={false}
                  translations={{
                    placeholder: 'What do you want to find?',
                  }}
                />
                <br />
                <h2 className="green-text">
                  Search Results
                  <Stats />
                </h2>

                <Hits hitComponent={Hit} />
                <Pagination showLast />
            </div>
          </div>
        </InstantSearch>
      </div>

      <footer>
        <div className="footer-flex p-lg-5">
          <div className="footer-logo-left">
            <img className="footer-logo" src="https://itma.ie/wp-content/themes/ITMA/images/itma-footer.svg" />

            <ul className="list-inline list-unstyled mt-3 ml-0">
              <li className="list-inline-item">
                <a href="https://twitter.com/itmadublin/" target="_blank"><img
                  className="footer-svg"
                  src="https://itma.ie/wp-content/themes/ITMA/images/twitter-square.svg"
                  alt="ITMA twitter"/>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.instagram.com/itmadublin/" target="_blank"><img
                  className="footer-svg"
                  src="https://itma.ie/wp-content/themes/ITMA/images/instagram-square.svg"
                  alt="ITMA Instagram"/>
                  </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.facebook.com/ITMADublin/" target="_blank"><img
                  className="footer-svg"
                  src="https://itma.ie/wp-content/themes/ITMA/images/facebook-square.svg"
                  alt="ITMA Facebook"/>
                  </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.youtube.com/user/ITMAVideos/" target="_blank"><img
                  className="footer-svg"
                  src="https://itma.ie/wp-content/themes/ITMA/images/youtube-square.svg"
                  alt="ITMA YouTube"/>
                  </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.tiktok.com/@irishtradmusicarchive/" target="_blank"><img
                  className="footer-svg"
                  src="https://itma.ie/wp-content/themes/ITMA/images/tiktok-square.svg"
                  alt="ITMA TikTok"/>
                  </a>
              </li>
            </ul>
          </div>
          <div className="footer-details">
            <p>
              Irish Traditional Music Archive /<br />
              Taisce Cheol Dúchais Éireann
              <br />
              73 Merrion Square
              <br />
              Dublin 2<br />
              Ireland
              <br />
              D02 WK75
              <br />
              <br />
              Telephone: +353 (0)1 661 9699
              <br />
              Email: info@itma.ie
            </p>
          </div>
          <div className="footer-logo-right hide-when-small">
            <img src="https://itma.ie/wp-content/themes/ITMA/images/footer-arts-council.svg" />
          </div>
        </div>

        <div className="container p-lg-5" style={{ textAlign: 'center' }}>
          <h2>Supporters</h2>

          <ul
            className="list-unstyled list-inline"
            style={{ textAlign: 'center' }}
          >
            <li className="list-inline-item pr-3">
              <img
                className="supporter-logo"
                src="https://itma.ie/wp-content/themes/ITMA/images/heritage_council_black.svg"
                alt="Heritage Council"
              />
            </li>
            <li className="list-inline-item pr-3">
              <img
                className="supporter-logo"
                src="https://itma.ie/wp-content/themes/ITMA/images/OPW.svg"
                alt="Office of Public Works"
              />
            </li>
            <li className="list-inline-item pr-3">
              <img
                className="supporter-logo"
                src="https://itma.ie/wp-content/themes/ITMA/images/arts-council.svg"
                alt="Arts Council"
              />
            </li>
            <li className="list-inline-item">
              <img
                className="supporter-logo"
                src="https://itma.ie/wp-content/themes/ITMA/images/arts-council-ni.svg"
                alt="Arts Council Northern Ireland"
              />
            </li>
          </ul>
        </div>

        <div className="container">
          <p>
            © Irish Traditional Music Archive — Taisce Cheol Dúchais Éireann |
            2024
            <br />
            Registered Charity Number: 20023218. Cookies Policy / Privacy Policy
          </p>
        </div>
      </footer>
    </div>
  );
}

const Hit = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formatArrayFields = (fields) => {
    if (Array.isArray(fields) && fields.length > 1) {
      return fields.map((field, index) => {
        if (index !== fields.length - 1) {
          return `${field}, `;
        } else return field;
      });
    } else return fields;
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const {
    title,
    url,
    creator,
    dateCreated,
    location,
    publisher,
    collection,
    identifier,
    rights,
    language,
    subject,
    format,
    database_source,
    description_one,
    description_two,
  } = props.hit;
  // TODO
  let className;
  if (database_source === WORDPRESS) {
    className = 'websiteRed';
  } else if (database_source === SOUTRON) {
    className = 'soutronGreen';
  } else if (database_source === ATOM) {
    className = 'atomGreen';
  }

  return (
    <div className={className}>
      <span className="pill-container">
        <ul className="source-list-indicator">
          {format && <li className="ais-RefinementList-count">{format}</li>}
        </ul>
      </span>

      <button className="hit-title" onClick={openModal}>
        <h2>{title}</h2>
      </button>

      <a href={url} target="_blank" rel="noopener noreferrer">
        See full record here
      </a>

      <ReactModal
        isOpen={isModalOpen}
        contentLabel={title}
        onRequestClose={closeModal}
      >
      <button onClick={closeModal}></button>

      <div className="modal-flex">
        <div className="modal-left">
        <h2>{title}</h2>
        </div>
        <div className="modal-left">
        {creator && creator.length !== 0 && (
          <p>Creator: {formatArrayFields(creator)}</p>
        )}
        {dateCreated && dateCreated.length !== 0 && (
          <p>Created Date: {formatArrayFields(dateCreated)}</p>
        )}
        {location && location.length !== 0 && (
          <p>Location: {formatArrayFields(location)}</p>
        )}
        {publisher && publisher.length !== 0 && (
          <p>Publisher: {formatArrayFields(publisher)}</p>
        )}
        {collection && collection.length !== 0 && (
          <p>Collection: {formatArrayFields(collection)}</p>
        )}
        {rights && rights.length !== 0 && (
          <p>Rights: {formatArrayFields(rights)}</p>
        )}
        {language && language.length !== 0 && (
          <p>Language: {formatArrayFields(language)}</p>
        )}
        {subject && subject.length !== 0 && (
          <p>Subject: {formatArrayFields(subject)}</p>
        )}
        {identifier && identifier.length !== 0 && (
          <p>Identifier: {formatArrayFields(identifier)}</p>
        )}
        {description_one && description_one.length !== 0 && (
          <p
            dangerouslySetInnerHTML={{ __html: formatArrayFields(description_one) }}
          />
        )}
        {description_two && description_two.length !== 0 && (
          <p
            dangerouslySetInnerHTML={{ __html: formatArrayFields(description_two) }}
          />
        )}
        </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default App;
