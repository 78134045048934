export const SOUTRON_URL_PREFIX = `https://itmacatalogues.ie/Portal/Default/en-GB/RecordView/Index/`; // append CID to this
export const ATOM_URL_PREFIX = `https://itma.accesstomemory.org/`; // append slug to this
export const HIGHEST_REVISION_VERSION_KEY = `HIGHEST_REVISION_VERSION`;
export const LAST_EXECUTION_TIME_KEY = `LAST_EXECUTION_TIME`;
export const LAST_UPDATE_ID_KEY = `LAST_UPDATE_ID`;
export const TYPESENSE_COLLECTION_NAME = `federatedSearch`;

export const database_sources = {
  WORDPRESS: 'Website',
  ATOM: 'Archive',
  SOUTRON: 'Library',
};
